import React from 'react';
import {Printer} from 'react-bootstrap-icons';
import {useTranslation} from 'react-i18next';

const PrintButton = () => {
    const {t} = useTranslation()
    const handlePrint = () => {
        window.print();
    };

    return (
        <>
            <button type="button" className="btn btn-primary text-center float-right print-button"
                    onClick={handlePrint}>
                <Printer/> {t('printbutton.uiPrintBtn', 'Print')}
            </button>
        </>
    );
};

export default PrintButton;
