import './Footer.css';
import {useTranslation} from 'react-i18next';

const Footer = () => {
    const {t} = useTranslation();

    return (
        <div className="footerContainer mt-4 mb-4">
            <div>
                &copy;2023 Markus Uhl
            </div>
            <div>
                <a href="mailto:markus.uhl@gmail.com?subject=Help with explAIner">{t('footer.uiFeedback', '...')}</a>
            </div>
        </div>
    );
};

export default Footer;
