import React from "react";
import './Header.css';

const Header = () => {

    return (
        <div id="header" className="header">
            <span className="explainer">expl<span className="ai">AI</span>ner</span>
        </div>
    )
};

export default Header;