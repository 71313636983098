import React from 'react';
import './Answer.css';
import {useTranslation} from 'react-i18next';
import PrintButton from '../Elements/Reusable/PrintButton';
import {HouseDoor} from 'react-bootstrap-icons';

const Answer = () => {
    const {t} = useTranslation();

    // Retrieve the serialized answer data from session storage
    const serializedAnswerData = JSON.parse(sessionStorage.getItem('lastDocument'));

    // Deserialize the answer data using eval()
    const answerData = JSON.parse(serializedAnswerData);

    // Do something with the retrieved data
    //console.log(answerData);

    // Do whatever is necessary when the user clicks the "next document" button
    const handleClick = () => {
        // Remove the 'lastDocument' item from session storage
        sessionStorage.removeItem('lastDocument');

        // Redirect to the home page
        window.location.href = '/';
    };

    return (
        <div className="text-center">
            <h1 className="mt-3 mb-1">{t('answer.uiPageTitle', 'Answer')}</h1>

            {answerData.mlImportance !== '' && (
                <>
                    <h2 className="mt-3 mb-1">{t('answer.uiImportance', 'Importance')}</h2>
                    <div>{answerData.mlImportance}</div>
                </>
            )}

            <h2 className="mt-3 mb-1">{t('answer.uiSummary', 'Summary')}</h2>
            <div>{answerData.mlSummary}</div>

            {answerData.mlNextSteps !== '' && (
                <>
                    <h2 className="mt-3 mb-1">{t('answer.uiNextSteps', 'Next Steps')}</h2>
                    <div>{answerData.mlNextSteps}</div>
                </>
            )}

            {answerData.mlContact !== '' && (
                <>
                    <h2 className="mt-3 mb-1">{t('answer.uiContacts', 'Contacts')}</h2>
                    <div dangerouslySetInnerHTML={{__html: answerData.mlContact}}/>
                </>
            )}

            {answerData.mlPrerequisites !== '' && (
                <>
                    <h2 className="mt-3 mb-1">{t('answer.uiPrerequisites', 'Prerequisites')}</h2>
                    <div>{answerData.mlPrerequisites}</div>
                </>
            )}

            <hr className="hr hr-blurry"/>

            {answerData.mlFaq && answerData.mlFaq.length > 0 && (
                <>
                    <h2 className="mt-3 mb-1">{t('answer.uiFaq', 'FAQ')}</h2>
                    {answerData.mlFaq.map((item) => (
                        <div key={item.question}>
                            <h3 className="mt-3 text-center">{item.question}</h3>
                            <div dangerouslySetInnerHTML={{__html: item.answer}}/>
                        </div>
                    ))}
                </>
            )}

            <hr className="hr hr-blurry"/>

            <div>
                <div className="mb-3">
                    <PrintButton/>
                </div>

                <div>
                    <button
                        type="button"
                        className="btn btn-primary text-center submit-button"
                        onClick={handleClick}
                        id="home-button"
                    >
                        <HouseDoor/> {t('answer.uiNextImage', 'Next Image')}
                    </button>
                </div>
            </div>


        </div>
    );
};

export default Answer;
