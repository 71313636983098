import './i18n';
import React, {useEffect, useState} from 'react';
import 'cropperjs/dist/cropper.css';
import './App.css';
import Header from './Elements/Structural/Header/Header';
import Intro from './Home/Intro';
import UploadForm from './Home/UploadForm';
import Footer from './Elements/Structural/Footer/Footer';
import Separator from './Elements/Reusable/Separator/Separator';

import Answer from './Answer/Answer';
import Cookies from 'js-cookie';
import {useDispatch, useSelector} from 'react-redux';
import LanguageSelector from './Home/LanguageSelector';
import {useTranslation} from 'react-i18next';
import WebFont from 'webfontloader';



const App = () => {
  const dispatch = useDispatch();
  const languages = useSelector(state => state.languages);
  const [showAnswer, setShowAnswer] = useState(false);
  const [answerData, setAnswerData] = useState(null);
  const { i18n } = useTranslation();

  const updateAnswerData = (data) => {
    setAnswerData(data);
  };

  const languageNames = {
    'ar': 'العربية',
    'en': 'English',
    'de': 'Deutsch',
    'it': 'Italiano',
    'hu': 'Magyar',
    'pl': 'Polski',
    'ru': 'Русский',
    'es': 'Español',
    'tk': 'Türkçe',
    'uk': 'Українська',
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Roboto:thin,bold', 'sans-serif']
      }
    });

    const storedAnswerData = sessionStorage.getItem('lastDocument');

    if (storedAnswerData) {
      setShowAnswer(true);
      setAnswerData(JSON.parse(storedAnswerData));
    } else {
      setShowAnswer(false);
      setAnswerData(null);
    }

    const languageCookie = Cookies.get('language');

    if (languageCookie) {
      dispatch({ type: 'CHANGE_LANGUAGE', language: languageCookie });
    } else {
      const browserLanguage = navigator.language.slice(0, 2);
      if (languages.includes(browserLanguage)) {
        dispatch({ type: 'CHANGE_LANGUAGE', language: browserLanguage });
      }
    }
  }, [dispatch, languages]);

  const handleLanguageChange = (selectedKey) => {
    dispatch({ type: 'CHANGE_LANGUAGE', language: selectedKey });
    Cookies.set('language', selectedKey);
    i18n.changeLanguage(selectedKey);
  };

  return (
    <div className="app-container container d-flex justify-content-center align-items-center">
      <Header />
      {showAnswer ? (
        <div id="answer" className="container">
          <Answer />
        </div>
      ) : (
        <div id="index" className="text-center">
          <Intro />
          <LanguageSelector handleLanguageChange={handleLanguageChange} languageNames={languageNames} />
          <Separator className="separator-1" hideWhenPreviewImageAvailable />
          <UploadForm setShowAnswer={setShowAnswer} updateAnswerData={updateAnswerData} />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default App;
