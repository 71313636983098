import React from 'react';
import {useSelector} from 'react-redux';
import './Separator.css';

const Separator = ({className, hideWhenPreviewImageAvailable}) => {
    const previewImageAvailable = useSelector(state => state.previewImageAvailable);

    if (hideWhenPreviewImageAvailable && previewImageAvailable) {
        return null;
    }

    return (
        <hr className={className}/>
    );
};

export default Separator;
