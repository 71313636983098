import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './UploadForm.css';
import Separator from '../Elements/Reusable/Separator/Separator';
import {useSelector, useDispatch} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {setPreviewImageAvailable} from '../store';
import serialize from 'serialize-javascript';
import {Trash} from 'react-bootstrap-icons';

const UploadForm = ({setShowAnswer, updateAnswerData}) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewSrcs, setPreviewSrcs] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const language = useSelector((state) => state.language);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (previewSrcs.length > 0) {
            dispatch(setPreviewImageAvailable(true));
        }
    }, [previewSrcs.length, dispatch]);

    const onFileChange = (event) => {
        const files = event.target.files;
        const newFileList = [...selectedFiles];
        const newPreviewList = [...previewSrcs];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            newFileList.push(file);
            newPreviewList.push(URL.createObjectURL(file));
        }

        setSelectedFiles(newFileList);
        setPreviewSrcs(newPreviewList);
    };

    const onDeleteFile = (index) => {
        const newFileList = [...selectedFiles];
        newFileList.splice(index, 1);
        setSelectedFiles(newFileList);

        const newPreviewList = [...previewSrcs];
        newPreviewList.splice(index, 1);
        setPreviewSrcs(newPreviewList);
    };

    const onSubmit = async () => {
        setIsUploading(true);
        try {
            const formData = new FormData();
            selectedFiles.forEach((file, index) => {
                formData.append(`imageInput${index}`, file, 'image.jpg');
            });
            formData.append('targetLanguage', language);

            const backendURL = '/api/v1/app/process';

            const response = await axios.post(backendURL, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // Serialize the answer data before storing
            const serializedAnswerData = serialize(response.data);

            // Save the serialized answer data in session storage
            sessionStorage.setItem('lastDocument', serializedAnswerData);

            if (response.data.redirect) {
                window.location.href = response.data.redirect;
            } else {
                // Answer received, show the answer container
                setShowAnswer(true);
                updateAnswerData(response.data);
            }
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div className="uploadForm">
            <div className="text-center">
                {selectedFiles.length === 0 ? (
                    <label className="custom-file-upload">
                        <input type="file" id="fileButton" onChange={onFileChange} accept="image/*" multiple/>
                        {t('index.uiUploadBtn', '...')}
                    </label>
                ) : null}
            </div>
            {selectedFiles.map((file, index) => (
                <div key={index} className="text-center">
                    {previewSrcs[index] ? (
                        <div className="mb-4">
                            <img src={previewSrcs[index]} alt="Preview" className="image-preview mt-3"/>
                            <button type="button" onClick={() => onDeleteFile(index)} className="delete-link-button">
                                <Trash/>
                                <span className="visually-hidden">{t('index.uiDelete', 'Delete')}</span>
                            </button>
                        </div>
                    ) : null}
                    {index === selectedFiles.length - 1 && (
                        <label className="custom-file-upload">
                            <input
                                type="file"
                                id={`fileButton${index}`}
                                onChange={onFileChange}
                                accept="image/*"
                                multiple
                            />
                            {t('index.uiUploadNextBtn', 'Upload Another Image')}
                        </label>
                    )}
                </div>
            ))}
            <Separator/>
            <label htmlFor="fileButton">
                {t('index.uiSubmitHelp', 'Select images and click Submit')}
            </label>
            <button
                type="button"
                className={`btn btn-primary ${selectedFiles.length === 0 ? 'deactivate-button' : ''}`}
                onClick={onSubmit}
                disabled={isUploading || selectedFiles.length === 0}
            >
                {isUploading ? (
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                ) : (
                    t('index.uiSubmitBtn', 'Submit')
                )}
            </button>
        </div>
    );
};

export default UploadForm;
