import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

const LanguageSelector = ({handleLanguageChange, languageNames}) => {
    const language = useSelector(state => state.language);
    const {t} = useTranslation();

    const previewImageAvailable = useSelector(state => state.previewImageAvailable);

    if (previewImageAvailable) {
        return null;  // Don't render anything if a preview image is available
    }

    return (
        <div id="languageSelector" className="text-center mt-4 mb-3">
            <label htmlFor="languageDropdown">{t('index.uiLanguageSelect', '...')}</label>
            <Dropdown id="languageDropdown" onSelect={handleLanguageChange}>
                <Dropdown.Toggle variant="primary" id="dropdown-basic">
                    {languageNames[language]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {Object.entries(languageNames).map(([key, name]) => (
                        <Dropdown.Item key={key} eventKey={key}>{name}</Dropdown.Item>
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};


export default LanguageSelector;
