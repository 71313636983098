import './Intro.css'; // This is where you should place your css
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

const Intro = () => {
    const {t} = useTranslation();
    const previewImageAvailable = useSelector(state => state.previewImageAvailable);

    if (previewImageAvailable) {
        return null;  // Don't render anything if a preview image is available
    }

    return (
        <div id="messages" className='text-center'>
            <h1 id="greeting" className="mt-4 mb-4">{t('index.uiGreeting', 'uiGreeting')}</h1>
            <div
                id="appHelp"
                className="mt-4 mb-4"
                dangerouslySetInnerHTML={{__html: t('index.uiAppHelp', '...')}}
            ></div>
        </div>
    )
};

export default Intro;