import { createStore, combineReducers } from 'redux';

// Define the initial state
const initialState = {
  language: 'en',
};

// Define the reducer for language
const languageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CHANGE_LANGUAGE':
      return action.language;
    default:
      return state;
  }
};

// Define the action creator for previeImageAvailable
export const setPreviewImageAvailable = (previewImageAvailable) => {
  return {
    type: 'SET_PREVIEW_IMAGE_AVAILABLE',
    payload: previewImageAvailable,
  };
};

// Define the reducer for previewImageAvailable
const previewImageAvailableReducer = (state = false, action) => {
  switch (action.type) {
    case 'SET_PREVIEW_IMAGE_AVAILABLE':
      return action.payload;
    default:
      return state;
  }
};

// Combine the reducers
const rootReducer = combineReducers({
  language: languageReducer,
  previewImageAvailable: previewImageAvailableReducer,
});

// Create the store
const store = createStore(rootReducer);

export default store;
